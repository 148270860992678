<template>
  <div class="specific-car-container">
    <ContentTitle>特定车列表</ContentTitle>
    <Wfilter
      :filterConfig="filterConfig"
      @openSpecificCarDialog="openSpecificCarDialog"
      @handleSearch="handleSearch"
      @changeProject="changeProject"
      @changeParking="changeParking"
    ></Wfilter>
    <Wtable
      :columns="columns"
      :showPagin="showPagin"
      :pagination="pagination"
      :tableData="tableData"
      @currentChange="currentChange"
      @cancel="openCancelDialog"
      @update="openSpecificCarDialog"
    >
    </Wtable>
    <wConfirmDialog
      ref="cancelDialog"
      @wConfirm="handleCancel"
      title="确定删除该特定车信息吗？"
    ></wConfirmDialog>
    <Wdialog
      ref="specificCarDialog"
      :title="specificCarTitle"
      width="50%"
      @wConfirm="handleSure"
    >
      <el-form
        ref="specificCarFormRef"
        :model="chargeFormData"
        :rules="chargeFormRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="parking" label="停车场">
              <el-select
                v-model="chargeFormData.parking"
                placeholder="请选择停车场"
              >
                <el-option
                  v-for="item in parkingOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="driver" label="驾驶人员">
              <el-select
                v-model="chargeFormData.driver"
                placeholder="请选择驾驶人员"
              >
                <el-option
                  v-for="item in driverOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="phone" label="联系电话">
              <el-input
                v-model="chargeFormData.phone"
                autocomplete="off"
                placeholder="请输入联系电话"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="parkingType" label="车辆类型">
              <el-select
                v-model="chargeFormData.parkingType"
                placeholder="请选择车辆类型"
              >
                <el-option
                  v-for="item in parkingTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="type" label="类型">
              <el-select v-model="chargeFormData.type" placeholder="请选择类型">
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="time" label="领用时间">
              <el-date-picker
                v-model="chargeFormData.time"
                type="date"
                placeholder="选择领用时间日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="ext" label="备注">
              <el-input
                type="textarea"
                rows="4"
                v-model="chargeFormData.ext"
                autocomplete="off"
                placeholder="请输入备注"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Wdialog>
  </div>
</template>
<script>
import { ref } from "vue";
import Wfilter from "@/components/wFilter/wFilter.vue";
import Wtable from "@/components/wTable/wTable.vue";
import Wdialog from "@/components/wDialog/wDialog.vue";
import wConfirmDialog from "@/components/wConfirmDialog/wConfirmDialog.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import config from "./specificCar.json";
export default {
  components: {
    Wfilter,
    Wtable,
    Wdialog,
    wConfirmDialog,
    ContentTitle,
  },
  setup() {
    const filterConfig = config.filterConfig;
    const columns = config.tableConfig.list;
    const showPagin = config.tableConfig.showPagin;
    const tableData = ref([
      {
        parking: "选项1",
        driver: "选项1",
        phone: "13595251478",
        parkingType: "选项1",
        time: "2020-07-10",
        type: "1",
        ext: "天啊，真的好多备注。",
      },
      {
        parking: "选项1",
        driver: "选项1",
        phone: "13595251478",
        parkingType: "选项1",
        time: "2020-07-10",
        type: "2",
        ext: "天啊，真的好多备注。",
      },
      {
        parking: "选项1",
        driver: "选项1",
        phone: "13595251478",
        parkingType: "选项1",
        time: "2020-07-10",
        type: "3",
        ext: "天啊，真的好多备注。",
      },
    ]);

    const pagination = ref({ total: 9 });
    const currentChange = (page) => {
      console.log(page);
    };
    const changeProject = (pro) => {
      console.log("pro==", pro);
    };
    function changeParking(data) {
      console.log(data);
    }
    const handleSearch = (kwd) => {
      console.log("===>", kwd);
    };
    const cancelDialog = ref(null);
    function openCancelDialog() {
      cancelDialog.value.show();
    }
    function handleCancel() {
      cancelDialog.value.close();
    }

    const parkingOptions = ref([
      {
        value: "选项1",
        label: "停车场1",
      },
      {
        value: "选项2",
        label: "双皮奶",
      },
    ]);
    const driverOptions = ref([
      {
        value: "选项1",
        label: "驾驶员1",
      },
      {
        value: "选项2",
        label: "双皮奶",
      },
    ]);
    const parkingTypeOptions = ref([
      {
        value: "选项1",
        label: "车辆类型1",
      },
      {
        value: "选项2",
        label: "双皮奶",
      },
    ]);
    const typeOptions = ref([
      {
        value: "1",
        label: "白名单",
      },
      {
        value: "2",
        label: "临时访客",
      },
      {
        value: "3",
        label: "黑名单",
      },
    ]);
    const chargeFormData = ref({
      parking: "",
      driver: "",
      phone: "",
      parkingType: "",
      time: "",
      type: "",
      ext: "",
    });
    const chargeFormRules = {
      parking: [{ required: true, message: "请选择停车场", trigger: "change" }],
      driver: [
        { required: true, message: "请输入驾驶人员", trigger: "change" },
      ],
      phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
      parkingType: [
        { required: true, message: "请选择车辆类型", trigger: "change" },
      ],
    };
    const specificCarDialog = ref(null);
    const specificCarFormRef = ref(null);
    const specificCarTitle = ref("");
    function openSpecificCarDialog(data) {
      if (data && data.row) {
        // 编辑
        specificCarTitle.value = "编辑特定车";
        const rowData = JSON.parse(JSON.stringify(data.row));
        chargeFormData.value = rowData;
      } else {
        // 新增
        specificCarTitle.value = "新增特定车";
        chargeFormData.value = {
          parking: "",
          driver: "",
          phone: "",
          parkingType: "",
          time: "",
          type: "",
          ext: "",
        };
      }

      specificCarDialog.value.show();
    }
    function handleSure() {
      if (specificCarFormRef.value) {
        specificCarFormRef.value.validate((valid) => {
          if (valid) {
            specificCarDialog.value.close();
          }
        });
      }
    }
    return {
      tableData,
      columns,
      showPagin,
      pagination,
      currentChange,
      openSpecificCarDialog,
      filterConfig,
      changeProject,
      handleSearch,
      changeParking,
      openCancelDialog,
      handleCancel,
      cancelDialog,
      specificCarDialog,
      specificCarFormRef,
      handleSure,
      chargeFormRules,
      chargeFormData,
      parkingTypeOptions,
      parkingOptions,
      specificCarTitle,
      driverOptions,
      typeOptions,
    };
  },
};
</script>

<style lang='scss'>
.specific-car-container {
  .header-action {
    .el-input__inner {
      background-color: var(--search-bg-color) !important;
    }
    ::-webkit-input-placeholder {
      color: var(--theme-color);
    }
  }
  .cancel-text {
    line-height: 80px;
  }
}
</style>